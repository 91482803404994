import { defineStore, StateTree } from 'pinia';

declare type IGoogleResponse = {
    html_attributions: Array<unknown>;
    result: {
        rating: number;
        reviews: Array<Array<unknown>>;
    };
    status: string;
};

export const useGoogleStore = defineStore('google', {
    state: (): StateTree => ({
        reviews: null,
        rating: 0,
    }),

    actions: {
        async fetchReviews() {
            try {
                const locale = useNuxtApp().$i18n.locale as unknown as { value: string};
                const response: IGoogleResponse = await $fetch('/api/google-reviews', {
                    method: 'get',
                    query: {
                        language: locale.value
                    }
                });

                if (response.status !== 'OK') {
                    console.error(response);
                    // throw new Error(response);
                }
                this.reviews = response.result.reviews;
                this.rating = response.result.rating;
            } catch (errorResponse) {
                console.error('ERROR', errorResponse)
                this.reviews = null;
            }
        },
    }
});
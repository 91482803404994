<script setup lang="ts">
const props = defineProps<{
  rating: number;
}>();

const getRatingStarIcon = (index: number) => {
  const halfStarMin = index + 0.25;
  const halfStarMax = index + 0.75;

  if (index === props.rating || props.rating > halfStarMax) return "mdi:star";
  if (index > props.rating || props.rating < halfStarMin)
    return "mdi:star-outline";
  return "mdi:star-half-full";
};
</script>

<template>
  <div v-if="rating" class="text-stars">
    <template v-for="index of 5" :key="index">
      <Icon
        :name="getRatingStarIcon(index - 1)"
        :inline="true"
        class="inline align-middle"
      ></Icon>
    </template>
  </div>
</template>
